import Container from "../../../atoms/layouts/container/Container";
import MainHeadingText from "../../../atoms/text_labels/MainHeadingText";

export default function Tagline() {
  return (
    <section className="section-phrase-worries grey-bg">
      <Container className="container phrase-grid flex-wrap-wrap">
        <div className="icon-section">
          <ion-icon name="stats-chart-outline" class="icon"></ion-icon>
        </div>
        <div className="text-section">
          <MainHeadingText className="dark-brown-font">
            Leave your college <br className="d-mobile-none" />
            admission worries to <br className="d-mobile-none" />
            us, so you can <br className="d-mobile-none" />
            focus on your <br className="d-mobile-none" />
            dream.
          </MainHeadingText>
        </div>
      </Container>
    </section>

  )
}