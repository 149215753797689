import { NavLink } from "react-router-dom";
import backgroundImgObj from "../../../assets/images/header_bg.jpg";
import Button from "../../atoms/form_elements/Button";
import Container from "../../atoms/layouts/container/Container";
import MainHeadingText from "../../atoms/text_labels/MainHeadingText";
import style from "./Header.module.scss";

export default function Header() {
  return (
    <header className={`main-header parent ${style.mainHeader} py-mobile-60`} style={{ backgroundImage: `url('${backgroundImgObj}')` }}>
      <Container className={`d-flex justify-content-space-between flex-wrap-wrap`}>
        <div>
          <MainHeadingText className="mb-20 dark-brown-font">
            Welcome to <br /> <span className="BrandFont">ConnectEdu</span><br />
          </MainHeadingText>
          <p className="main-sub-heading-tag">Your Gateway to Personalized
            <br className="d-mobile-none"/>
            Education Solutions!
          </p>
          <NavLink to="/book-consultation">
            <Button themeType="theme1">
              <span className="mr-10">
                Book A Free Consultation
              </span>
              <ion-icon name="paper-plane-outline"></ion-icon>
            </Button>
          </NavLink>
        </div>

        <div className="image-slot">
          {/* <img src={modelObj} className="model-image" /> */}
          {/* <img src={"https://plus.unsplash.com/premium_photo-1669652639356-f5cb1a086976?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} className="model-image" /> */}
        </div>

      </Container>
    </header>
  )
}