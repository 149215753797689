let idCounter = 500;


const servicesOffered = [
  {
    id: idCounter++,
    icon_name: "wallet-outline",
    name: "Student Scholarship Guidance",
    short_description: "Let's explore financial opportunities together! Our team is passionate about helping you uncover and apply for scholarships that perfectly match your academic achievements and dreams.",
    long_description: ""
  },
  {
    id: idCounter++,
    icon_name: "home-outline",
    name: "Accommodation Guidance",
    short_description: "Transition into student life smoothly with our Accommodation Guidance. We're not just finding you a place to stay; we're ensuring it feels like a true home throughout your academic journey.",
    long_description: ""
  },
  {
    id: idCounter++,
    icon_name: "bus-outline",
    name: "Travel Assistance",
    short_description: "Experience a worry-free journey with our Travel Assistance. From planning your itinerary to handling travel logistics, consider us your travel-savvy friends ensuring your smooth transition from home to your new academic adventure.",
    long_description: ""
  },
  {
    id: idCounter++,
    icon_name: "airplane-outline",
    name: "Airport Transfer",
    short_description: "Make a stress-free entrance with our Airport Transfer service. We arrange more than just a ride; we provide reliable transportation from the airport to your new home, ensuring a comfortable and safe start.",
    long_description: ""
  },
  {
    id: idCounter++,
    icon_name: "podium-outline",
    name: "Bangalore City Guidance:",
    short_description: "Get to know Bangalore like a local with our City Guidance. We're not just showing you around; we're sharing insights into the local culture, must-know services, and the hidden gems of the city, making you feel right at home.",
    long_description: ""
  },
]


const courses = [
  {
    id: idCounter++,
    course_domain: "Medical",
    image_url: `${process.env.PUBLIC_URL}/images/gnm.jpg`,
    courses: [
      {
        id: idCounter++,
        name: "GNM Nursing"
      },
      {
        id: idCounter++,
        name: "B.Sc Nursing"
      },
      {
        id: idCounter++,
        name: "M.Sc Nursing"
      },
      {
        id: idCounter++,
        name: "D.Pharm- Diploma"
      },
      {
        id: idCounter++,
        name: "Pharm D"
      },
      {
        id: idCounter++,
        name: "M.Pharm"
      },
      {
        id: idCounter++,
        name: "Physiotherapy"
      },
      {
        id: idCounter++,
        name: "Master of Physiotherapy (MPT)"
      },
      {
        id: idCounter++,
        name: "M.sc Microbiology"
      },
    ]
  },
  {
    id: idCounter++,
    course_domain: "Technology",
    image_url: `${process.env.PUBLIC_URL}/images/engineering.jpg`,
    courses: [
      {
        id: idCounter++,
        name: "BCA"
      },
      {
        id: idCounter++,
        name: "BCA Analytics"
      },
      {
        id: idCounter++,
        name: "BCA with Cloud Computing"
      },
      {
        id: idCounter++,
        name: "MCA"
      },
      {
        id: idCounter++,
        name: "B.Tech"
      },
      {
        id: idCounter++,
        name: "M.Tech"
      },
      {
        id: idCounter++,
        name: "B.Sc Data Science"
      }
    ]
  },
  {
    id: idCounter++,
    course_domain: "Management",
    image_url: `${process.env.PUBLIC_URL}/images/business.jpg`,
    courses: [
      {
        id: idCounter++,
        name: "BBA Branding and Advertising"
      },
      {
        id: idCounter++,
        name: "BBA Business Analytics"
      },
      {
        id: idCounter++,
        name: "MBA"
      },
      {
        id: idCounter++,
        name: "BBA Tourism and Hospitality Management"
      },
    ]
  },
  {
    id: idCounter++,
    course_domain: "Arts & Commerce",
    image_url: `${process.env.PUBLIC_URL}/images/arts.jpg`,
    courses: [
      {
        id: idCounter++,
        name: "B.Com Tourism"
      },
      {
        id: idCounter++,
        name: "Applied Economics"
      },
      {
        id: idCounter++,
        name: "Psychology"
      },
      {
        id: idCounter++,
        name: "English Literature"
      },
      {
        id: idCounter++,
        name: "Visual Communication"
      },
      {
        id: idCounter++,
        name: "Humanities"
      },
      {
        id: idCounter++,
        name: "Economics"
      },
      {
        id: idCounter++,
        name: "History"
      },
      {
        id: idCounter++,
        name: "Political Science"
      },
      {
        id: idCounter++,
        name: "B.Com CMA"
      },
      {
        id: idCounter++,
        name: "BA Visual Communication"
      },
      {
        id: idCounter++,
        name: "B.Com International Business and Finance"
      },
      {
        id: idCounter++,
        name: "B.Com Integrated with ACCA"
      },
    ]
  },
  {
    id: idCounter++,
    course_domain: "Science (B.Sc/M.Sc)",
    image_url: `${process.env.PUBLIC_URL}/images/science.jpg`,
    courses: [
      {
        id: idCounter++,
        name: "Botany, Microbiology"
      },
      {
        id: idCounter++,
        name: "Big Data Analytics"
      },
      {
        id: idCounter++,
        name: "Computer Science"
      },
      {
        id: idCounter++,
        name: "Chemistry"
      },
      {
        id: idCounter++,
        name: "Zoology"
      },
      {
        id: idCounter++,
        name: "Biotechnology"
      },
      {
        id: idCounter++,
        name: "Physics"
      },
      {
        id: idCounter++,
        name: "Mathematics"
      },
      {
        id: idCounter++,
        name: "Statistics"
      },
    ]
  },
  {
    id: idCounter++,
    course_domain: "Hospitality",
    image_url: `${process.env.PUBLIC_URL}/images/hospitality.jpg`,
    courses: [
      {
        id: idCounter++,
        name: "Cabin Crew"
      },
      {
        id: idCounter++,
        name: "Hotel Management"
      },
      {
        id: idCounter++,
        name: "Tourism"
      },
    ]
  }
]

export { courses, servicesOffered }

