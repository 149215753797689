import Container from "../../components/atoms/layouts/container/Container";
import MainHeadingText from "../../components/atoms/text_labels/MainHeadingText";


export default function About() {
  return (
    <div className="grey-bg">
      <Container className="py-100 long-para-content">

        <MainHeadingText className="dark-brown-font mb-100">About Us</MainHeadingText>

        <div className="mb-50">
          <h4 className="mb-15 long-para-heading">
            Welcome to ConnectEdu – Your Gateway to Personalized Education Solutions!
          </h4>

          <p className="long-para-text">
            At ConnectEdu, we take pride in being more than just an education service provider; we are your dedicated partner on the journey to academic success. In a world where choices abound, ConnectEdu stands out as the best, offering a modern twist to educational support.
          </p>
        </div>



        <div className="mb-50">
          <h4 className="mb-15 long-para-heading">
            Why Choose ConnectEdu?
          </h4>

          <p className="mb-20 long-para-text">
            <b>Personalized Guidance:</b> At ConnectEdu, we understand that each student is unique, with individual dreams and aspirations. That's why we don't believe in one-size-fits-all solutions. Our team of experienced consultants is committed to providing personalized guidance tailored to your specific needs.
          </p>
          <p className="mb-20 long-para-text">
            <b>Comprehensive Support:</b> Our commitment goes beyond securing admissions in prestigious colleges. ConnectEdu takes a holistic approach, offering support in travel logistics, accommodation arrangements, and guiding you through every step of the process. From the initial application to your first day on campus, we ensure a seamless experience.
          </p>
          <p className="long-para-text">
            <b>Your Journey, Our Priority:</b> We recognize that embarking on an educational journey can be both exciting and overwhelming. ConnectEdu aims to alleviate the stress by being your reliable partner throughout the entire process. Consider us your lending hand, dedicated to making your academic dreams a reality.
          </p>
        </div>


        <div className="mb-50">
          <h4 className="mb-15 long-para-heading">What Sets ConnectEdu Apart?</h4>
          <p className="mb-20 long-para-text">
            <b>Tailored Solutions:</b> ConnectEdu is not just about finding a college; it's about finding the right fit for you. Our consultants work closely with you to understand your academic goals, preferences, and unique circumstances, ensuring a personalized roadmap to success.
          </p>

          <p className="mb-20 long-para-text">
            <b>Modern Approach:</b> In a rapidly evolving educational landscape, ConnectEdu embraces a modern approach to consultancy. We leverage technology, industry insights, and a network of global partners to provide you with up-to-date information and innovative solutions.
          </p>

          <p className="mb-20 long-para-text">
            <b>Your Success, Our Mission:</b> ConnectEdu is not just a consultancy; it's a mission. Our success is measured by your success. From your first consultation to the day you step into your chosen institution, ConnectEdu is committed to making your educational journey memorable, fulfilling, and successful.
          </p>
        </div>


        <p className="mb-20 long-para-text">
          Join us at ConnectEdu, where education meets personalized excellence. Let's embark on this transformative journey together – because your success is our priority!
        </p>
      </Container>
    </div>
  )
}