import Container from "../../components/atoms/layouts/container/Container";
import EmailChatInline from "../../components/atoms/social_media/ChatTriggerBtn/EmailappChatInline";
import GsmChatInline from "../../components/atoms/social_media/ChatTriggerBtn/GsmCallInline";
import WhatsappChatInline from "../../components/atoms/social_media/ChatTriggerBtn/WhatsappChatInline";
import MainHeadingText from "../../components/atoms/text_labels/MainHeadingText";

export default function ContactUs() {
  return (
    <div className="grey-bg full-page-background">
      <Container className="py-100 long-para-content">
        <MainHeadingText className="section-header-1 dark-brown-font mb-20">
          We are
          <br />
          Happy to help</MainHeadingText>


        <h4>You may reach out to us using any of these. 
          <br/>We try our best to reply in not more than one business day.</h4>

        <WhatsappChatInline className="mt-50"/>
        <EmailChatInline className="mt-10"/>
        <GsmChatInline className="mt-10"/>



      </Container>
    </div>
  )
}