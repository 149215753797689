import { NavLink } from "react-router-dom"
import style from "./DesktopNavbar.module.scss"

export default function DesktopNavbar({ navItems, className = ''}) {
  return (
    <div className={`${style.DesktopNav} ${className}`}>
      <ul>

        {navItems.map(navItem =>
          <li key={navItem.id}>
            <NavLink to={navItem.href}>{navItem.name}</NavLink>
          </li>
        )}
      </ul>
    </div>
  )
}