import { useGlobalContext } from "../../../../contexts/GlobalContext"
import style from "./ChatTriggerBtn.module.scss"

export default function WhatsappChatInline({ className = "", chatLinkUrl = "" }) {

  const { whatsapp } = useGlobalContext("businessAttributes");

  return (
    <a href={chatLinkUrl || whatsapp} className={`d-flex align-items-center ${className} ${style.ChatContainer} ${style.WhatsappChatInline}`} target="_blank">
      <ion-icon style={{ fontSize: "30px" }}
        name="logo-whatsapp"></ion-icon>
      <span className="ml-10">
        Chat on WhatsApp
      </span>
    </a>
  )
}