import { useRef, useState } from "react";
import style from "./ExpandingBlock.module.scss";

export default function ExpandingBlock({ triggerHeader, bodyText, className = "" }) {

  const [expanded, setExpandedState] = useState(false);
  const expansionDOM = useRef();

  function toggleExpansion() {

    console.log("expansionDOM.current");
    console.log(expansionDOM);

    if(expanded) {
      expansionDOM.current.style.height = `0px`;
      setTimeout(()=>{setExpandedState(false)}, 500)
    } else {
      let scrollHeight = expansionDOM.current.scrollHeight;
      expansionDOM.current.style.height = `${scrollHeight}px`
      setTimeout(()=>{
        // expansionDOM.current.style.height = `auto`
        setExpandedState(true)
      }, 500)
    }

  }

  return (
    <div className={`${className} ${style.ExpandingBlock}`}>
      <button className={style.headTrigger} onClick={() => { toggleExpansion() }}>{triggerHeader}</button>
      <div ref={expansionDOM} className={`${style.expandingBlock} ${expanded ? 'active' : ''}`}>
        <div className={style.expandingBlockInnerWrapper}>
          {bodyText}
        </div>
      </div>
    </div>
  )
}