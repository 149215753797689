import { useGlobalContext } from "../../../../contexts/GlobalContext";
import style from "./ChatTriggerBtn.module.scss"

export default function EmailChatInline({className="", chatLinkUrl=""}) {

  const { email } = useGlobalContext("businessAttributes");
  
  return (
    <a href={chatLinkUrl || `mailto:${email}`} className={`d-flex align-items-center ${className} ${style.ChatContainer} ${style.EmailChatInline}`} target="_blank">
      <ion-icon style={{ fontSize: "30px" }}
        name="mail-outline"></ion-icon>
      <span className="ml-10">
        Send us an Email
      </span>
    </a>
  )
}