import { NavLink } from "react-router-dom";
import Container from "../../atoms/layouts/container/Container";
import style from "./Footer.module.scss";
import SvgSprite from "../../atoms/svg_sprite/SvgSprite";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function Footer() {

  const footerNavItems = useContext(GlobalContext)?.globalState?.footerNavItems;

  return (

    <footer className="section-footer">
      <Container className={`d-flex justify-content-space-between flex-wrap-wrap align-items-center`}>
        <ul className={`${style.socialLinks} p-0`}>
          <li>
            <a>
              <SvgSprite name="app_logo" width="100" height="50" />
            </a>
          </li>
          <li>
            <a className="color-inherit" href="https://www.instagram.com/connectedu.in/" target="_blank">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>
          <li>
            <a className="color-inherit" target="_blank">
              <ion-icon name="logo-youtube"></ion-icon>
            </a>
          </li>
        </ul>
        <div>
          <ul className={`${style.linksContainer} p-0`}>
            {footerNavItems?.length > 0 && footerNavItems.map(eachFooterItem =>
              <li key={eachFooterItem.id}>
                <NavLink to={eachFooterItem.href}>
                  {eachFooterItem.name}
                </NavLink>
              </li>
            )}
            <li>
              <a>
                &copy;{new Date().getFullYear()} connectedu.in
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>

  )
}