import { NavLink } from "react-router-dom";
import style from "./MobileNavbar.module.scss";
import { useState } from "react";

export default function MobileNavbar({ navItems, className = "" }) {

  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <div className={`${className} ${style.MobileNavbarContainer}`}>
      <button className={`${style.menuHamburgerBtn}`} onClick={()=>{setMenuOpenState(true)}}>
        <ion-icon name="menu-outline"></ion-icon>
      </button>


      <div className={`${style.mobileSlidingMenu} ${menuOpenState === true ? 'active' : ''}`}>

        <button className={style.menuCloseBtn} onClick={()=>{setMenuOpenState(false)}}>
          <ion-icon name="close-outline"></ion-icon>
        </button>

        <ul>
          {
            navItems.map(navItem =>
              <li key={navItem.id}>
                <NavLink to={navItem.href}  onClick={()=>{setMenuOpenState(false)}}>{navItem.name}</NavLink>
              </li>
            )
          }
        </ul>
      </div>


    </div>
  )
}