import { useContext } from "react";
import Container from "../../../atoms/layouts/container/Container";
import DisplayGrid from "../../../atoms/layouts/grid/DisplayGrid";
import MainHeadingText from "../../../atoms/text_labels/MainHeadingText";
import CardTypeB from "../../cards/card_type_b/CardTypeB";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import Button from "../../../atoms/form_elements/Button";
import CardHeaderText from "../../../atoms/text_labels/CardHeaderText";
import Paragraph from "../../../atoms/text_labels/Paragraph";

export default function Services() {

  const servicesOffered = useContext(GlobalContext).globalState.servicesOffered

  return (
    <section className="section-services">
      <Container className="container">
        <MainHeadingText className="mb-30 dark-brown-font text-center">Our Services</MainHeadingText>


        <Container className="text-center long-para-content mb-50">
          <CardHeaderText className="mb-10">
            All Inclusive ConnectEdu Package
          </CardHeaderText>
          <Paragraph className="text-align-mobile-justify">
            Embark on your educational adventure with exclusive, all-in-one package, crafted just for you. Think of us as your friendly companions throughout this exciting journey, offering a helping hand at every step. Our dedicated consultants become your personal mentors, guiding you through the maze of admissions. We're here to make sure you secure your spot in the dream institution you've set your sights on.
            Apart from admission guidance, here's what our package entails:
          </Paragraph>
        </Container>


        <DisplayGrid col="2" colMobile="1" gap="20">
          {servicesOffered && servicesOffered.map(service =>
            <CardTypeB key={service.id} iconName={service.icon_name} name={service.name} shortDescription={service.short_description} longDescription={service.long_description} />
          )}
        </DisplayGrid>


        {/* <div className="text-center">
          <Button themeType={"theme1"} className="mt-100">Learn more about our services</Button>
        </div> */}

      </Container>
    </section>
  )
}