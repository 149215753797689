import Container from "../../components/atoms/layouts/container/Container";
import MainHeadingText from "../../components/atoms/text_labels/MainHeadingText";


export default function TermsConditions() {
  return (
    <div className="grey-bg">
      <Container className="py-100 long-para-content">
        <MainHeadingText className="section-header-1 dark-brown-font mb-100">ConnectEdu <br></br>Terms & Conditions</MainHeadingText>


        <h4 className="mb-15 long-para-heading">Introduction</h4>
        <p className="mb-30 long-para-text">
          Welcome to ConnectEdu's website. These Terms and Conditions govern your use of this platform. By accessing and using our website, you fully accept and adhere to these terms. If you disagree with any part of these Terms and Conditions, we kindly ask you to refrain from using our website.
        </p>

        <h4 className="mb-15 long-para-heading">Intellectual Property Rights</h4>
        <p className="mb-30 long-para-text">
          All intellectual property and materials on this website, except for content or information you own, are owned by ConnectEdu or its licensors, and all rights are reserved. You are granted a limited license, subject to the restrictions outlined in these Terms, to view the information or material on this website.
        </p>

        <h4 className="mb-15 long-para-heading">Service Overview:</h4>
        <p className="mb-30 long-para-text">
          ConnectEdu offers education consultancy services, including academic counseling, travel assistance, accommodation assistance, scholarship guidance and Bangalore city guidance.
          All services are provided with the utmost commitment to personalized guidance and client satisfaction.
        </p>

        <h4 className="mb-15 long-para-heading">Consultation and Counseling:</h4>
        <p className="mb-30 long-para-text">
          Academic counseling services aim to provide guidance on college selection, course options, and academic planning.
          The information provided during counseling is based on the client's academic background, preferences, and career goals.
        </p>

        <h4 className="mb-15 long-para-heading">Client Responsibilities:</h4>
        <p className="mb-30 long-para-text">
          Clients are responsible for providing accurate and truthful information during the consultation and application process.
          Clients must adhere to the rules and regulations of the chosen educational institution.
        </p>

        <h4 className="mb-15 long-para-heading">Confidentiality:</h4>
        <p className="mb-30 long-para-text">
          ConnectEdu respects the confidentiality of client information and ensures that personal details are handled with the utmost care.
          Information shared during consultations will only be used for the purpose of providing education consultancy services.
        </p>

        <h4 className="mb-15 long-para-heading">Restrictions</h4>
        <p className="mb-30 long-para-text">
          You are explicitly prohibited from:
          Publishing any website content or material in any media
          Selling, commercializing, or sublicensing any website content or material
          Using this website in a manner that causes damage or impacts user access
          Engaging in data harvesting, mining, or extracting activities
          Using this website for marketing or advertising purposes
        </p>

        <h4 className="mb-15 long-para-heading">Indemnification</h4>
        <p className="mb-30 long-para-text">
          By using this website, you agree to indemnify ConnectEdu from and against all liabilities, demands, costs, damages, causes of action, and expenses (including reasonable attorney’s fees) arising from your breach of any of these Terms.
        </p>

        <h4 className="mb-15 long-para-heading">Limitation of Liability:</h4>
        <p className="mb-30 long-para-text">
          ConnectEdu is not liable for any direct or indirect consequences arising from decisions made by the client based on the information provided during counseling. We are not responsible for any indirect, special, or consequential liability related to your use of this website.
        </p>

        <h4 className="mb-15 long-para-heading">Governing Law:</h4>
        <p className="mb-30 long-para-text">
          These terms and conditions are governed by the laws of the jurisdiction where ConnectEdu operates.
        </p>

        <h4 className="mb-15 long-para-heading">Amendments:</h4>
        <p className="mb-30 long-para-text">
          ConnectEdu reserves the right to update or modify these terms and conditions. Clients will be notified of any changes, and the revised terms will be applicable to future transactions.
        </p>

        <p className="mb-30 long-para-text">
          By engaging with ConnectEdu, clients acknowledge and agree to abide by these terms and conditions. For any queries or clarifications, clients are encouraged to contact ConnectEdu's customer support.
        </p>

      </Container>
    </div>
  )
}