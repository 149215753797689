import { useContext } from "react";
import { createContext } from "react";

const GlobalContext = createContext();
const GlobalContextProvider = GlobalContext.Provider;


function useGlobalContext(stateName){
  const globalContext = useContext(GlobalContext);
  return stateName? globalContext.globalState[stateName] : globalContext
}

export {GlobalContext, GlobalContextProvider, useGlobalContext}