import style from "./CardTypeA.module.scss";

export default function CardTypeA({ imgUrl, title, listItems }) {
  return (
    <div className={`box ${style.CardTypeA}`}>
      <div className="thumb-container">
        <img className="thumb-img" src={imgUrl} />
      </div>
      <h4 className="text-center title">{title}</h4>
      <div className="text-center display-items">
        {listItems?.length > 0 && listItems.map((listItem, index) =>
          <p className="display-item" key={index}>{listItem.name}</p>
        )}
      </div>
    </div>
  )
}