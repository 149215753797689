import './App.scss';
import "./assets/fonts/font_face.css";
import "./assets/scss/scss_index.scss";
import "react-toastify/dist/ReactToastify.css";
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/organisms/footer/Footer';
import Navbar from './components/organisms/navbar/Navbar';
import { AuthContextProvider } from './contexts/AuthContext';
import { GlobalContextProvider } from './contexts/GlobalContext';
import AppRouter from './router/AppRouter';
import { ToastContainer } from 'react-toastify';
import { businessAttributes, footerNavItems, navbarItems } from './configs/appConfigs';
import { courses, servicesOffered } from './data/app_data';

function App() {

  const [globalState, setGlobalState] = useState({
    courses: courses,
    navbarItems: navbarItems,
    footerNavItems: footerNavItems,
    servicesOffered: servicesOffered,
    businessAttributes: businessAttributes,
    theme: "theme-light"
  });

  const globalStateReducer = {
    setTheme(themeName) {
      setGlobalState({ ...globalState, theme: themeName });
    },
  }


  const [authState, setAuthState] = useState({});


  const authStateReducer = {
    setUserInfo() {
      setAuthState()
    }
  }


  return (
    <GlobalContextProvider value={{ globalState, globalStateReducer }}>
      <AuthContextProvider value={{ authState, authStateReducer }}>
        <BrowserRouter>
          <div className="App">
            <Navbar navItems={globalState.navbarItems} />
            <main className='Main'>
              <AppRouter />
            </main>
            <Footer />
            <ToastContainer
              position="bottom-right"
            />
          </div>
        </BrowserRouter>
      </AuthContextProvider>
    </GlobalContextProvider>
  );
}

export default App;
