let baseURl = process.env.REACT_APP_BASE_URL;


class PayLoad {
  constructor(method, data, config) {
    this.method = method;
    if (data) {
      this.body = JSON.stringify(data);
    }
  }
}

const Api = {

  get(url, params) { },
  async post(path, data, cofig) {
    let response = await fetch(new URL(path, baseURl).href, new PayLoad("post", data));
    response = await response.json();
    return response;
  },
  delete(url, data) { },
}


export default Api;