import Header from "../../components/organisms/header/Header";
import Courses from "../../components/organisms/home_sections/courses/Courses";
import OurCompany from "../../components/organisms/home_sections/our_company/OurCompany";
import Services from "../../components/organisms/home_sections/services/Services";
import Tagline from "../../components/organisms/home_sections/tagline/Tagline";


export default function Home(){
  return (
    <div>
      <Header/>
      <Services/>
      <Courses/>
      <OurCompany/>
      <Tagline/>
    </div>
  )
}