import { useContext } from "react"
import { GlobalContext } from "../../../../contexts/GlobalContext"
import MainHeadingText from "../../../atoms/text_labels/MainHeadingText"
import CardTypeA from "../../cards/card_type_a/CardTypeA"
import DisplayGrid from "../../../atoms/layouts/grid/DisplayGrid";
import Container from "../../../atoms/layouts/container/Container";
import Paragraph from "../../../atoms/text_labels/Paragraph";

export default function Courses() {

  const courses = useContext(GlobalContext)?.globalState.courses || [];

  return (
    <section className="affiliate grey-bg">
      <div className="container px-mobile-20 px-tablet-20">
        <Container className="text-center long-para-content mb-50">
          <MainHeadingText className="mb-20 dark-brown-font text-center">Courses</MainHeadingText>
          <Paragraph>We work with a variety of very well-established institutions that will help your educational and professional dreams come true. We also assist students with a stream and courses that could potentially reveal their hidden abilities and be competitive for the rest of their careers.
          </Paragraph>
        </Container>

        <DisplayGrid col={3} colMobile={1} colTablet={2} gap={30}>
          {courses.map(courseDomain =>
            <CardTypeA key={courseDomain.id} imgUrl={courseDomain.image_url} title={courseDomain.course_domain} listItems={courseDomain.courses} />
          )}
        </DisplayGrid>

      </div>
    </section>
  )
}