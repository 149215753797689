let idCounter = 1;

const businessAttributes = {
  name : "ConnectEdu",
  email: "info@connectedu.in",
  phone : "+91 7005102448",
  whatsapp: "https://wa.me/917005102448",
}

const navbarItems = [
  {
    id: idCounter++,
    name: "Home",
    href: "/"
  },
  {
    id: idCounter++,
    name: "About Us",
    href: "about-us"
  },
  {
    id: idCounter++,
    name: "Contact Us",
    href: "contact-us"
  },
  // {
  //   id: idCounter++,
  //   name: "Feedback",
  //   href: "feedback"
  // },
]


const footerNavItems = [
  {
    id: idCounter++,
    name: "About Us",
    href: "/about-us"
  },
  {
    id: idCounter++,
    name: "Terms",
    href: "/terms"
  },
  {
    id: idCounter++,
    name: "Privacy Policy",
    href: "/privacy-policy"
  },
  {
    id: idCounter++,
    name: "Contact Us",
    href: "contact-us"
  },
]

export { navbarItems, footerNavItems, businessAttributes }