import { NavLink } from "react-router-dom"
import logoImg from "../../../assets/images/connect_edu_logo.png"
import Container from "../../atoms/layouts/container/Container"
import style from "./Navbar.module.scss"
import DesktopNavbar from "./desktop_navbar/DesktopNavbar"
import SvgSprite from "../../atoms/svg_sprite/SvgSprite"
import MobileNavbar from "./mobile_navbar/MobileNavbar"

export default function Navbar({ navItems }) {
  return (
    <nav className={`${style.navbarMain} py-10 py-mobile-5`}>
      <Container className={`d-flex justify-content-space-between align-items-center px-mobile-10`}>
        <div>
          <NavLink to="/">
            {/* <img className={style.logoImg} src={logoImg} /> */}
            <SvgSprite className={`${style.AppLogo} d-block`} name="app_logo" width="119" height="60"/>
          </NavLink>
        </div>
        <div>
          <DesktopNavbar navItems={navItems} className={style.desktopNavBar}/>
          <MobileNavbar  navItems={navItems} className={style.mobileNavBar}/>
        </div>
      </Container>
    </nav>
  )
}